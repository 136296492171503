import { generateScopedName, generateScopedPath } from '@libero/utilities/generic-routing';
import { RouteRecordRaw } from 'vue-router';

export const generateContainerRoutes = (key: string): RouteRecordRaw[] => [
  {
    path: generateScopedPath('create', 'container', key),
    name: generateScopedName('container.create', 'container', key),
    component: () => import('./views/modals/Create.vue'),
    props: { scope: key },
    meta: {
      delay: true,
      permission: 'container.store',
    },
  },
  {
    path: generateScopedPath(':id', 'container', key),
    component: () => import('./views/modals/Show.vue'),
    props: { scope: key },
    children: [
      {
        path: 'properties',
        name: generateScopedName('container.show', 'container', key),
        component: () => import('./views/tabs/Properties.vue'),
      },
      {
        path: 'properties/edit',
        name: generateScopedName('container.edit', 'container', key),
        component: () => import('./views/tabs/PropertiesEdit.vue'),
      },
      {
        path: 'map',
        name: generateScopedName('container.map', 'container', key),
        component: () => import('./views/tabs/Map.vue'),
      },
      {
        path: 'streetsmart',
        name: generateScopedName('container.streetsmart', 'container', key),
        component: () => import('./views/tabs/Streetsmart.vue'),
      },
      {
        path: 'documents',
        name: generateScopedName('container.documents', 'container', key),
        component: () => import('./views/tabs/Documents.vue'),
      },
      {
        path: 'containers',
        name: generateScopedName('container.containers', 'container', key),
        component: () => import('./views/tabs/Containers.vue'),
      },
      {
        path: 'tickets',
        name: generateScopedName('container.tickets', 'container', key),
        component: () => import('./views/tabs/Tickets.vue'),
      },
      {
        path: 'notes',
        name: generateScopedName(`container.notes`, 'container', key),
        component: () => import('./views/tabs/Notes.vue'),
      },
      {
        path: 'log',
        name: generateScopedName('container.log', 'container', key),
        component: () => import('./views/tabs/Log.vue'),
      },
      {
        path: 'entity/:entityId/properties',
        name: generateScopedName('container.entity.show', 'container', key),
        component: () => import('./views/tabs/Entity.vue'),
      },
      {
        path: 'entity/:entityId/properties/edit',
        name: generateScopedName('container.entity.edit', 'container', key),
        component: () => import('./views/tabs/EntityEdit.vue'),
      },
      {
        path: 'entity/:entityId/documents',
        name: generateScopedName('container.entity.documents', 'container', key),
        component: () => import('./views/tabs/EntityDocuments.vue'),
      },
      {
        path: 'entity/:entityId/log',
        name: generateScopedName('container.entity.log', 'container', key),
        component: () => import('./views/tabs/EntityLog.vue'),
      },
    ],
    meta: {
      delay: true,
      permission: 'container.show',
    },
  },
];
